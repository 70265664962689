import { IDTODadosCalculoCustoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTODadosCalculoCustoProduto';
import { IDTORetornoCalculoCustoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTORetornoCalculoCustoProduto';
import {
  IDTODadosCalculoTributacao, IDTODadosCalculoTributacaoIcms, IDTODadosCalculoTributacaoImpostoFederal, IDTODadosCalculoTributacaoIpi, IDTODadosCalculoTributacaoSubstituicaoTributaria,
} from '@/models/DTO/Tributacoes/IDTODadosCalculoTributacao';
import { IDTORetornoCalculoTributacao } from '@/models/DTO/Tributacoes/IDTORetornoCalculoTributacao';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { IRecebimentoMercadoriaItem } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import { INotaFiscalIncidenciaCalculo, INotaFiscalItem } from '@/models/Entidades/Fiscal/INotaFiscal';
import ServicoTipoCusto from './Cadastros/Precificacao/ServicoTipoCusto';
import ServicoUtilitario from './MeuSistema/ServicoUtilitario';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { INotaFiscalServicoItem } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import { IDTODadosCalculoTributacaoServico } from '@/models/DTO/Tributacoes/IDTODadosCalculoTributacaoServico';
import { IDTORetornoCalculoTributacaoServico } from '@/models/DTO/Tributacoes/IDTORetornoCalculoTributacaoServico';
import { ERegimeTributario } from '@/models/Enumeradores/MeuSistema/Empresas/ERegimeTributario';

class ServicoCalculosComerciais {
  /**
   * Cálculo padrão: Valor total = ((quantidade * valorUnitario) - desconto)
   * @param quantidade - Quantidade do produto
   * @param valorUnitario - Valor unitário do produto em Reais
   * @param desconto - Desconto do produto em Reais
   * @param acrescimo - Acréscimo do produto em Reais
   * @returns valorTotal - Valor total do Produto
   */
  public calculaValorTotal(quantidade: number, valorUnitario: number, desconto: number, acrescimo: number): number {
    return parseFloat(((quantidade * valorUnitario) + acrescimo - desconto).toFixed(2));
  }

  /**
   * Cálculo Valor Rateio: (valorASerRateado * (SubTotalProduto / TotalGeralProduto))
   * @param subTotalProduto - SubTotal do produto
   * @param totalGeralProduto - Total geral do do produto
   * @param valorASerRateado - Valor Total a ser Rateado (Frete,Seguro, Outras etc)
   * @returns valorTotal - Valor total do Produto
   */
  public calculaValorRateio(subTotalProduto: number, totalGeralProduto: number, valorASerRateado: number): number {
    return parseFloat((valorASerRateado * (subTotalProduto / totalGeralProduto)).toFixed(2));
  }

  /**
   * Cálculo padrão: Preço venda = (custo + ((custo / 100) * markup))
   * @param custo - Quantidade do produto
   * @param markup - Valor unitário do produto em Reais
   * @returns precoVenda - Valor Preço Venda
   */
  public calculaPrecoVenda(custo: number, markup: number): number {
    return (custo + ((custo / 100) * markup));
  }

  public formatarValor2CasasDecimais(valor: number): number {
    return parseFloat(valor.toFixed(2));
  }

  /**
   * Cálculo Tributações/Impostos (IPI, PIS, COFINS, ICMS , SUBSTITUIÇÃO, SIMPLES NACIONAL, DESONERAÇÃO baseado nas informações do item Nota Fiscal
   * @param itemNotaFiscal - Produto,Quantidade, Valores e todas informações necessárias para efetuar o cálculo
   * @returns IDTORetornoCalculoTributacao - Objeto com informações de Base de cálculo e valor dos impostos.
   */
  public async calcularTributacoes(itemNotaFiscal:INotaFiscalItem, incidencias: INotaFiscalIncidenciaCalculo[], ufEmitente?: string): Promise<IDTORetornoCalculoTributacao> {
    const dadosCalculo:IDTODadosCalculoTributacao = {} as IDTODadosCalculoTributacao;
    dadosCalculo.ufEmitente = ufEmitente === undefined ? '' : ufEmitente;
    dadosCalculo.codigoProdutoDefinicao = itemNotaFiscal.codigoProdutoDefinicao;
    dadosCalculo.ncm = itemNotaFiscal.impostos.ncm;
    dadosCalculo.origem = itemNotaFiscal.impostos.origemMercadoria;
    if (UtilitarioGeral.valorValido(itemNotaFiscal.impostos.cst)) {
      dadosCalculo.cst = itemNotaFiscal.impostos.cst;
    } else {
      dadosCalculo.cst = itemNotaFiscal.impostos.cson;
    }

    dadosCalculo.quantidade = itemNotaFiscal.quantidade;
    dadosCalculo.precoUnitario = itemNotaFiscal.precoUnitario;
    dadosCalculo.valorTotalProduto = itemNotaFiscal.valorTotal;
    dadosCalculo.valorDesconto = itemNotaFiscal.valorDesconto;
    dadosCalculo.valorFrete = itemNotaFiscal.valorFrete;
    dadosCalculo.valorSeguro = itemNotaFiscal.valorSeguro;
    dadosCalculo.outrasDespesas = itemNotaFiscal.outrasDespesas;
    dadosCalculo.incidencias = incidencias;
    dadosCalculo.icms = {} as IDTODadosCalculoTributacaoIcms;
    dadosCalculo.icms.aliquotaIcms = itemNotaFiscal.impostos.icms.aliquotaIcms;
    dadosCalculo.icms.modalidadeBaseCalculoIcms = itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms;
    dadosCalculo.icms.reducaoBaseCalculoIcms = itemNotaFiscal.impostos.icms.reducaoBaseCalculoIcms;
    dadosCalculo.icms.aliquotaSimplesNacional = itemNotaFiscal.impostos.icms.aliquotaSimplesNacional;

    dadosCalculo.substituicaoTributaria = {} as IDTODadosCalculoTributacaoSubstituicaoTributaria;
    dadosCalculo.substituicaoTributaria.aliquotaSubstituicaoTributaria = itemNotaFiscal.impostos.st.aliquotaSubstituicaoTributaria;
    dadosCalculo.substituicaoTributaria.modalidadeBaseCalculoSubstituicao = itemNotaFiscal.impostos.st.modalidadeBaseCalculoSubstituicao;
    dadosCalculo.substituicaoTributaria.aliquotaIcmsInterno = itemNotaFiscal.impostos.st.aliquotaIcmsInterno;
    dadosCalculo.substituicaoTributaria.reducaoBaseCalculoSubstituicao = itemNotaFiscal.impostos.st.reducaoBaseCalculoSubstituicao;
    dadosCalculo.substituicaoTributaria.margemValorAdicionado = itemNotaFiscal.impostos.st.margemValorAdicionado;
    dadosCalculo.substituicaoTributaria.pautaIcmsSubstituicao = itemNotaFiscal.impostos.st.pautaIcmsSubstituicao;

    dadosCalculo.ipi = {} as IDTODadosCalculoTributacaoIpi;
    dadosCalculo.ipi.cst = itemNotaFiscal.impostos.ipi.cstIpi;
    dadosCalculo.ipi.aliquota = itemNotaFiscal.impostos.ipi.aliquotaIpi;
    dadosCalculo.ipi.tipoCalculo = itemNotaFiscal.impostos.ipi.tipoCalculoIpi;
    dadosCalculo.ipi.valorUnidadeTributavel = itemNotaFiscal.impostos.ipi.valorUnidadeTributavelIpi;

    dadosCalculo.pis = {} as IDTODadosCalculoTributacaoImpostoFederal;
    dadosCalculo.pis.cst = itemNotaFiscal.impostos.pis.cstPis;
    dadosCalculo.pis.aliquota = itemNotaFiscal.impostos.pis.aliquotaPis;
    dadosCalculo.pis.tipoCalculo = itemNotaFiscal.impostos.pis.tipoCalculoPis;
    dadosCalculo.pis.reducaoBaseCalculo = itemNotaFiscal.impostos.pis.reducaoBaseCalculoPis;
    dadosCalculo.pis.valorUnidadeTributavel = itemNotaFiscal.impostos.pis.valorUnidadeTributavelPis;

    dadosCalculo.cofins = {} as IDTODadosCalculoTributacaoImpostoFederal;
    dadosCalculo.cofins.cst = itemNotaFiscal.impostos.cofins.cstCofins;
    dadosCalculo.cofins.aliquota = itemNotaFiscal.impostos.cofins.aliquotaCofins;
    dadosCalculo.cofins.tipoCalculo = itemNotaFiscal.impostos.cofins.tipoCalculoCofins;
    dadosCalculo.cofins.reducaoBaseCalculo = itemNotaFiscal.impostos.cofins.reducaoBaseCalculoCofins;
    dadosCalculo.cofins.valorUnidadeTributavel = itemNotaFiscal.impostos.cofins.valorUnidadeTributavelCofins;

    const retornoCalculoTributacoes = await new ServicoUtilitario().calcularTributacoes(dadosCalculo);

    return retornoCalculoTributacoes;
  }

  public async calcularTributacoesServico(itemNotaFiscalServico:INotaFiscalServicoItem, regimeTributario: ERegimeTributario): Promise<IDTORetornoCalculoTributacaoServico> {
    const dadosCalculo:IDTODadosCalculoTributacaoServico = {} as IDTODadosCalculoTributacaoServico;
    dadosCalculo.regimeTributario = regimeTributario;
    dadosCalculo.valorServico = itemNotaFiscalServico.valorServico;
    dadosCalculo.valorDesconto = 0;
    dadosCalculo.valorDesconto += itemNotaFiscalServico.impostos.descontoCondicionado;
    dadosCalculo.valorDesconto += itemNotaFiscalServico.impostos.descontoIncondicionado;
    dadosCalculo.issRetido = itemNotaFiscalServico.impostos.issRetido;
    dadosCalculo.aliquotaIss = itemNotaFiscalServico.impostos.aliquotaIss;
    dadosCalculo.aliquotaPis = itemNotaFiscalServico.impostos.aliquotaPis;
    dadosCalculo.aliquotaCofins = itemNotaFiscalServico.impostos.aliquotaCofins;
    dadosCalculo.aliquotaInss = itemNotaFiscalServico.impostos.aliquotaInss;
    dadosCalculo.aliquotaIr = itemNotaFiscalServico.impostos.aliquotaIr;
    dadosCalculo.aliquotaCsll = itemNotaFiscalServico.impostos.aliquotaCsll;

    const retornoCalculoTributacoes = await new ServicoUtilitario().calcularTributacoesServico(dadosCalculo);

    return retornoCalculoTributacoes;
  }

  /**
   * Cálculo custo produto conforme a formula Tipo Custo
   * @param itemRecebimento - Produto,Quantidade, Valores e todas informações necessárias para efetuar o cálculo
   * @returns IDTORetornoCalculoCustoProduto - Lista com informações do valor de cada custo.
   */
  public async calcularCustoItemRecebimento(itemRecebimento:IRecebimentoMercadoriaItem, tiposCustos: ITipoCusto[]): Promise<IDTORetornoCalculoCustoProduto[]> {
    const dadosCalculo:IDTODadosCalculoCustoProduto = {} as IDTODadosCalculoCustoProduto;
    dadosCalculo.tipoCustos = [];
    itemRecebimento.custos.forEach((custoItemRecebimento) => {
      const tipoCusto = tiposCustos.find((c) => c.codigo === custoItemRecebimento.codigoTipoCusto);
      if (tipoCusto !== undefined) {
        dadosCalculo.tipoCustos.push(tipoCusto);
      }
    });
    dadosCalculo.quantidadeProduto = itemRecebimento.itemNotaFiscal.quantidade;
    dadosCalculo.quantidadeEstoqueAtual = 10;
    dadosCalculo.valorCustoAtual = 1;
    dadosCalculo.valorTotalProduto = itemRecebimento.itemNotaFiscal.valorTotal;
    dadosCalculo.valorIcms = itemRecebimento.itemNotaFiscal.impostos.icms.valorIcms;
    dadosCalculo.valorSubstituicaoTributaria = itemRecebimento.itemNotaFiscal.impostos.st.valorSubstituicaoTributaria;
    dadosCalculo.valorDifal = 0;
    dadosCalculo.valorDesconto = itemRecebimento.itemNotaFiscal.valorDesconto;
    dadosCalculo.valorIpi = itemRecebimento.itemNotaFiscal.impostos.ipi.valorIpi;
    dadosCalculo.valorPis = itemRecebimento.itemNotaFiscal.impostos.pis.valorPis;
    dadosCalculo.valorCofins = itemRecebimento.itemNotaFiscal.impostos.cofins.valorCofins;
    dadosCalculo.valorIcmsFreteConhecimento = 0;
    if (itemRecebimento.itemNotaFiscal.impostos.importacao !== undefined) {
      dadosCalculo.valorIof = itemRecebimento.itemNotaFiscal.impostos.importacao.valorIof;
      dadosCalculo.valorImpostoImportacao = itemRecebimento.itemNotaFiscal.impostos.importacao.valorImportacao;
      dadosCalculo.valorDespesasAduaneiras = itemRecebimento.itemNotaFiscal.impostos.importacao.despesasAduaneiras;
    } else {
      dadosCalculo.valorIof = 0;
      dadosCalculo.valorImpostoImportacao = 0;
      dadosCalculo.valorDespesasAduaneiras = 0;
    }

    dadosCalculo.valorFrete = itemRecebimento.itemNotaFiscal.valorFrete;
    dadosCalculo.valorSeguro = itemRecebimento.itemNotaFiscal.valorSeguro;
    dadosCalculo.valorOutrasDespesas = itemRecebimento.itemNotaFiscal.outrasDespesas;
    dadosCalculo.fatorConversao = itemRecebimento.itemNotaFiscal.fatorConversao;

    const retornoCalculoCustoProduto = await new ServicoTipoCusto().calcularCustoProduto(dadosCalculo);

    return retornoCalculoCustoProduto;
  }
}

export default ServicoCalculosComerciais;
